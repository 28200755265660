<template>
  <div class="">
    <div class="navtop row-between mb20">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane
          v-for="(item, index) in navList"
          :key="index"
          :label="item.label"
          :name="item.value"
        ></el-tab-pane>
      </el-tabs>
      <div class="top-r flex1 row-end">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="order-list mb20" v-if="orderList.length && activeName == 0">
      <div
        class="order-item item-pro mb10"
        v-for="(item, index) in orderList"
        :key="index"
      >
        <div class="pro-title row-between nr lighter">
          <div class="row normal" v-if="item.shop">
            <span class="mr20 ml20 row">
              <el-image
                style="width: 24px; height: 24px; border-radius: 50%"
                class="mr10"
                :src="item.shop.logo"
                fit="cover"
              ></el-image
              >{{ item.shop.name }}
            </span>
          </div>

          <span class="mr20 xs">
            <span class="mr20"
              >下单时间：{{ item.create_time.slice(0, 10) }}</span
            >订单编号：{{ item.order_sn }}</span
          >
        </div>
        <div class="row pro-content wrap">
          <div class="flex3">
            <div
              class="row pro-item"
              v-for="(items, indexs) in item.order_goods_un_comment"
              :key="indexs"
            >
              <div class="flex5 pro-info row">
                <el-image
                  class="ml20 mr20 img"
                  :src="items.goods_item.image"
                  fit="cover"
                ></el-image>
                <div class="info-detail flex1 column">
                  <router-link
                    :to="`/prodetail?id=${items.goods_id}`"
                    class="name line1"
                    >{{ items.goods_name }}
                  </router-link>
                  <p class="attribute line1 lighter sm mb5">
                    {{ items.goods_item.spec_value_str }}
                  </p>
                  <make-priceformat
                    :price="items.goods_price"
                    :subscriptSize="16"
                    :firstSize="16"
                    :secondSize="16"
                  >
                  </make-priceformat>
                </div>
              </div>

              <div class="flex1 row">
                <div class="flex2 pro-other column">
                  <button
                    @click="
                      $router.push({
                        path: '/evaluation',
                        query: { id: items.id },
                      })
                    "
                    type="primary"
                    class="status-btn white row-center"
                    v-if="!items.is_comment"
                  >
                    填写评价
                  </button>
                  <button
                    type="reset"
                    class="status-btn white row-center"
                    v-else
                  >
                    已评价
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-checkbox-group> -->
    </div>
    <div class="order-list mb20" v-if="orderList.length && activeName == 1">
      <div
        class="order-item item-pro mb10"
        v-for="(item, index) in orderList"
        :key="index"
      >
        <div class="pro-title row-between nr lighter">
          <div class="row normal">
            <span class="mr20 ml20 row">
              <el-image
                style="width: 24px; height: 24px; border-radius: 50%"
                class="mr10"
                :src="item.shop_logo"
                fit="cover"
              ></el-image
              >{{ item.shop_name }}
            </span>
          </div>

          <span class="mr20 xs">
            <span class="mr20"
              >下单时间：{{ item.create_time.slice(0, 10) }}
            </span>
            <!-- 订单编号：{{ item.order_sn }} -->
          </span>
        </div>
        <div class="row pro-content wrap">
          <div class="flex3" v-if="item.goods_item">
            <div class="row pro-item">
              <div class="flex5 pro-info row">
                <el-image
                  class="ml20 mr20 img"
                  :src="item.goods_item.image"
                  fit="cover"
                ></el-image>
                <div class="info-detail flex1 column">
                  <p class="name line1">{{ item.goods.name }}</p>
                  <p class="attribute line1 lighter sm mb5">
                    {{ item.goods_item.spec_value_str }}
                  </p>
                  <make-priceformat
                    :price="item.order_goods.total_pay_price"
                    :subscriptSize="16"
                    :firstSize="16"
                    :secondSize="16"
                  ></make-priceformat>
                </div>
              </div>

              <div class="flex1 row">
                <div class="flex2 pro-other column">
                  <button type="reset" class="status-btn white row-center">
                    已评价
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </el-checkbox-group> -->
    </div>
    <el-empty description="暂无评价订单" v-if="!orderList.length"></el-empty>
    <div class="row-end">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
        v-if="orderList.length"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import MakePagination from "@/components/MakePagination";
import MakeCountDown from "@/components/MakeCountDown";
export default {
  name: "",
  props: {},
  components: {
    "make-pagination": MakePagination,
    "make-countdown": MakeCountDown,
  },
  data() {
    return {
      navList: [
        {
          label: "待评价",
          value: "0",
        },
        {
          label: "已评价",
          value: "1",
        },
      ],
      orderList: [],
      activeName: "",
      page_no: 1,
      page_size: 10,
      orderObject: {},
    };
  },
  watch: {
    activeName(val) {
      this.page = 1;
      if (val == 1) {
        this.getCommentOrder();
      } else {
        this.getUnCommentOrder();
      }
    },
  },
  methods: {
    resetList(e) {
      this.page_no = e;
      if (this.activeName == 1) {
        this.getCommentOrder();
      } else {
        this.getUnCommentOrder();
      }
    },
    /**
     * @description 获取评价列表
     * @returns void
     */
    getUnCommentOrder() {
      let { page_no, page_size } = this;
      this.$api
        .getUnCommentOrderApi({
          page_no,
          page_size,
        })
        .then((res) => {
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderList = res.data.lists;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
        });
    },
    /**
     * @description 获取评价列表
     * @returns void
     */
    getCommentOrder() {
      let { page_no, page_size } = this;
      this.$api
        .getCommentOrderApi({
          page_no,
          page_size,
        })
        .then((res) => {
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderList = res.data.lists;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
        });
    },
  },
  created() {
    this.activeName = this.$route.query.type;
    this.getUnCommentOrder();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.screen {
  .search-box {
    height: 45px;
    border: 2px solid #c7c7c7;
    background-color: #c7c7c7;

    .el-input {
      width: 262px;
    }

    /deep/ .el-input__inner {
      border: none;
      height: 41px;
      line-height: 1;
      border-radius: 0;
    }

    button {
      height: 41px;
      line-height: 1;
      width: 107px;
      background-color: #c7c7c7;
      cursor: pointer;
    }
  }
}

.order-list {
  overflow: hidden;

  .order-item {
    /deep/ .el-checkbox__label {
      display: none;
    }

    .flex5 {
      flex: 5;
      overflow: hidden;
    }

    > div {
      flex-shrink: 0;
      overflow: hidden;
    }

    &.item-th {
      height: 44px;
      background: $-color-center;
      //   padding-right: 25px;
      padding-left: 40px;
      margin-bottom: 15px;

      .pd {
        padding-left: 16px;
        box-sizing: border-box;
      }
    }

    &.item-pro {
      box-shadow: 0px 1px 0px 0px rgba(184, 184, 184, 0.4);

      .pro-title {
        background-color: $-color-border;
        padding: 6px 0;

        .service {
          margin-right: 36px;

          img {
            width: 30px;
            height: 30px;
            margin-right: 3px;
          }
        }
      }

      .pro-content {
        background-color: $-color-center;

        // padding-right: 25px;
        > div {
          flex-shrink: 0;
          overflow: hidden;
        }

        .pro-item {
          border-bottom: $-solid-border;
          border-right: $-solid-border;

          &:last-child {
            border-bottom: none;
          }
        }

        .pro-info {
          padding: 30px 0 30px 15px;

          .info-detail {
            // padding-right: 20px;
            overflow: hidden;
            flex-shrink: 0;
          }

          .attribute {
            padding-right: 15px;
          }

          .img {
            width: 64px;
            height: 64px;
          }
        }

        .pro-price {
          align-items: center;
        }

        .pro-status {
          text-align: center;
        }

        .pro-other {
          align-items: center;

          .time {
            margin-bottom: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }

          button {
            width: 90px;
            height: 35px;
            margin: 5px 0;
          }
        }
      }
    }
  }
}

.navtop {
  border-bottom: 1px solid $-color-primary;

  /deep/ .el-tabs {
    .el-tabs__header {
      border: none;
      margin-bottom: 0;

      .el-tabs__nav,
      .el-tabs__item {
        border: none;
      }

      .el-tabs__item {
        font-size: 20px;
        height: 46px;
        line-height: 46px;
        padding: 0 24px;

        &::after {
          content: "";
          width: 1px;
          height: 20px;
          background-color: $-color-border;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          color: $-color-primary;
        }

        &.is-active {
          background-color: $-color-primary;
          color: white;

          &::after {
            display: none;
          }
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

button {
  cursor: pointer;
}
</style>
